// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-single-blog-js": () => import("./../src/templates/singleBlog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-category-js": () => import("./../src/templates/singleCategory.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-single-page-js": () => import("./../src/templates/singlePage.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-service-js": () => import("./../src/templates/singleService.js" /* webpackChunkName: "component---src-templates-single-service-js" */),
  "component---src-templates-single-project-js": () => import("./../src/templates/singleProject.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ask-for-offer-js": () => import("./../src/pages/ask-for-offer.js" /* webpackChunkName: "component---src-pages-ask-for-offer-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-sent-js": () => import("./../src/pages/contact-sent.js" /* webpackChunkName: "component---src-pages-contact-sent-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

